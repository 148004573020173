import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, onMounted, reactive } from 'vue';
import { selectSchedulingByUid } from '@/api/attendance';
import moment from 'moment';
const typeColumns = [{
  title: '序号',
  dataIndex: 'numNO'
}, {
  title: '姓名',
  dataIndex: 'userName'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '岗位',
  dataIndex: 'jobName'
}];
export default {
  setup() {
    const groupMonthValue = ref(moment().startOf('day').format('YYYY-MM'));

    const handleMonth = data => {
      getTableHeader(data);
      getTypographyData();
    };

    const typeNameAndTable = reactive({
      groupName: null,
      list: []
    });
    let dayNum = ref(30);
    const typographyColumns = ref([]);

    const getTableHeader = month => {
      typographyColumns.value = [];
      let arr = month.split('-');

      switch (arr[1]) {
        case '01':
        case '03':
        case '05':
        case '07':
        case '08':
        case '10':
        case '12':
          dayNum.value = 31;
          break;

        case '04':
        case '06':
        case '09':
        case '11':
          dayNum.value = 30;
          break;

        case '02':
          dayNum.value = 28;
          break;
      }

      let weekObj = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      };

      for (let i = 1; i <= dayNum.value; i++) {
        let str = weekObj[moment(`${month}-${i}`).isoWeekday()];
        typographyColumns.value.push({
          title: `${i}${str}`,
          dataIndex: i,
          slots: {
            customRender: i
          },
          width: '50px'
        });
      } // return columns

    };

    const typographyData = ref([]);
    const typographyLoading = ref(false);

    const getTypographyData = async () => {
      const param = {
        scheduleTime: groupMonthValue.value
      };
      typographyLoading.value = true;
      let res = await selectSchedulingByUid(param);

      if (res.code === 200) {
        const arr = [];
        typographyData.value = [];

        if (res.data) {
          let schedulePlan = JSON.parse(res.data.schedulePlan);
          let obj = {};
          schedulePlan.forEach((item, index) => {
            obj[index + 1] = { ...item
            };
          });
          arr.push(obj);
          typeNameAndTable.groupName = res.data.groupName;
          typeNameAndTable.list = [];

          if (res.data.list && res.data.list.length) {
            res.data.list.forEach((item, index) => {
              typeNameAndTable.list.push({ ...item,
                numNO: index + 1
              });
            });
          }
        }

        Object.assign(typographyData.value, arr);
      } else {
        _message.error(res.message);

        typographyLoading.value = false;
      }

      typographyLoading.value = false;
    };

    onMounted(() => {
      getTableHeader(groupMonthValue.value);
      getTypographyData();
    });
    return {
      groupMonthValue,
      handleMonth,
      dayNum,
      typographyColumns,
      typographyData,
      typographyLoading,
      getTypographyData,
      typeNameAndTable,
      typeColumns
    };
  }

};